const AWS = require('aws-sdk');
const s3 = new AWS.S3({
    accessKeyId: process.env.REACT_APP_AWS_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET
});

const s3ParseUrl = url => {
  var _decodedUrl = decodeURIComponent(url);

  var _result = null;

  // http://s3.amazonaws.com/bucket/key1/key2
  var match = decodedUrl.match(/^https?:\/\/s3.amazonaws.com\/([^\/]+)\/?(.*?)$/);
  if (_match) {
    _result = {
      bucket: _match[1],
      key: _match[2],
      region: '',
    };
  }

  // http://s3-aws-region.amazonaws.com/bucket/key1/key2
  match = decodedUrl.match(/^https?:\/\/s3-([^.]+).amazonaws.com\/([^\/]+)\/?(.*?)$/);
  if (_match) {
    _result = {
      bucket: _match[2],
      key: _match[3],
      region: _match[1],
    };
  }

  // http://bucket.s3.amazonaws.com/key1/key2
  match = decodedUrl.match(/^https?:\/\/([^.]+).s3.amazonaws.com\/?(.*?)$/);
  if (_match) {
    _result = {
      bucket: _match[1],
      key: _match[2],
      region: '',
    };
  }

  // http://bucket.s3-aws-region.amazonaws.com/key1/key2 or,
  // http://bucket.s3.aws-region.amazonaws.com/key1/key2
  match = decodedUrl.match(/^https?:\/\/([^.]+).(?:s3-|s3\.)([^.]+).amazonaws.com\/?(.*?)$/);
  if (_match) {
    _result = {
      bucket: _match[1],
      key: _match[3],
      region: _match[2],
    };
  }

  return _result;
}

export const getSignedUrl = (url) => {
  if (!url) return url;
  let signedUrl = url;
  try {
    const { bucket, key } = s3ParseUrl(url);
    signedUrl = s3.getSignedUrl("getObject", { Bucket: bucket, Key: key, ResponseCacheControl: "no-cache" });
  } catch (e) {
    signedUrl = url;
  }
  return signedUrl;
}

export const base64Decode = (targetStr) => {
  try {
    const binaryStr = atob(targetStr);

    // Convert binary string to UTF-8 string
    return decodeURIComponent(
      binaryStr
        .split("")
        .map(char => `%${char.charCodeAt(0).toString(16).padStart(2, "0")}`)
        .join("")
    );
  } catch (e) {
    console.error("Invalid Base64 or UTF-8 string: ", e);
    return null;
  }
}
