import convert from 'convert-units';
import { Map, fromJS } from 'immutable';
import React, { useState } from 'react';
import * as Three from 'three';
import { HDRCubeTextureLoader } from 'three/examples/jsm/loaders/HDRCubeTextureLoader.js';
import * as SceneCreator from '../../../../src/components/viewer3d/scene-creator';
import {
  ARROW_COLOR,
  OBJTYPE_GROUP,
  OBJTYPE_MESH,
  SHADE_DARK_PURPLE_COLOR,
  SHAPE_SVG_DEPTH,
  SHAPE_SVG_PADDING,
  SHAPE_SVG_WIDTH,
  UNIT_CENTIMETER,
  UNIT_INCH,
  STATUS_WARNING_COLOR,
  STATUS_WARNING_LIGHT_COLOR
} from '../../../../src/constants';
import { Item } from '../../../../src/models';
import * as GeomUtils from './geom-utils';
import { loadGLTF } from './load-obj';

// env Map ///////////////
const paramsCounter = {
  envMap: 'HDR',
  roughness: 0.6,
  metalness: 0.3,
  exposure: 1
  // debug: true
};
const params = {
  envMap: 'HDR',
  roughness: 0.9,
  metalness: 0.1,
  metalness_glossy: 0.2,
  exposure: 1.0
  // debug: false
};

const paramsModel = {
  envMap: 'HDR',
  roughness: 0.9,
  metalness: 0.2,
  exposure: 1.0
  // debug: false
};
const hdrUrls = ['px.hdr', 'nx.hdr', 'py.hdr', 'ny.hdr', 'pz.hdr', 'nz.hdr'];
let textureCube = new HDRCubeTextureLoader()
  .setPath('/catalog/envMap/')
  .load(hdrUrls, function() {
    textureCube.magFilter = Three.LinearFilter;
    textureCube.needsUpdate = true;
  });
// /////////////////////////

let cachedObject = {}; // cached Object for quickly load-3d

// render 2d function//////
export function render2DItem(
  element,
  layer,
  scene,
  sizeinfo,
  layoutpos,
  is_corner,
  shape_svg
) {
  const [hover, setHover] = useState(false);
  let { x, y, rotation } = element;
  let el_DSN = 'el_DSN',
    doorStylesKeys = [];
  let _element = element.toJS();
  if (_element.doorStyle !== undefined) {
    if (_element.doorStyle.doorStyles !== undefined) {
      doorStylesKeys = Object.keys(_element.doorStyle.doorStyles);
    }
  }
  if (
    (doorStylesKeys.includes('euro_length') ||
      doorStylesKeys.includes('euro_width') ||
      doorStylesKeys.includes('euro_shape_svg')) &&
    _element.doorStyle.doorStyles.is_euro_cds
  ) {
    el_DSN = _element.doorStyle.door_style_name;
  } else {
    el_DSN = 'el_DSN';
  }
  let width, depth, el_euro_length, el_euro_width, el_euro_length_unit, el_euro_width_unit, el_is_euro_cds, el_euro_shape_svg;
  if (doorStylesKeys.length > 0) {
    el_euro_length = _element.doorStyle.doorStyles.euro_length;
    el_euro_width = _element.doorStyle.doorStyles.euro_width;
    el_is_euro_cds = _element.doorStyle.doorStyles.is_euro_cds;
    el_euro_shape_svg = _element.doorStyle.doorStyles.euro_shape_svg;
  }
  if (el_euro_length === undefined && el_euro_width === undefined) {
    el_DSN = "el_DSN";
  }
  if (el_DSN === "Euro & Frameless") {
    // sizeinfo["depth"] = el_euro_length;
    // sizeinfo["width"] = el_euro_width;
  }
  let tempWidth = element.properties.get('width');
  let tempDepth = element.properties.get('depth');
  width = { length: tempWidth.get('_length'), unit: tempWidth.get('_unit') };
  depth = { length: tempDepth.get('_length'), unit: tempDepth.get('_unit') };
  let originalWidth = convert(sizeinfo.width)
    .from('in')
    .to('cm');
  let originalDepth = convert(sizeinfo.depth)
    .from('in')
    .to('cm');
  let newWidth = convert(width.length)
    .from(width.unit)
    .to('cm');
  let newDepth = convert(depth.length)
    .from(depth.unit)
    .to('cm');
  let padding = convert(SHAPE_SVG_PADDING)
    .from(UNIT_INCH)
    .to(UNIT_CENTIMETER);
  let angle = element.rotation + 90;
  let textRotation = 0;
  if (Math.sin((angle * Math.PI) / 180) < 0) {
    textRotation = 180;
  }
  let color = '#eee';
  if (layoutpos == 'Base') {
    color = '#3f8db3';
  }
  if (layoutpos == 'Tall') {
    color = '#93b3be';
  }
  if (layoutpos == 'Wall') {
    color = '#48b08dcc';
  }

  const splitStr = [];
  const txtContent = [];
  const lineCount = 0; //parseInt(((newWidth) / 8 - 0.51).toFixed(), 10) - 1;
  const rowCount = 0; //parseInt((element.type.length / lineCount - 0.51).toFixed(), 10);

  // Get type
  let type = element.type;
  let objSKU = this.obj.sku_number;
  if (objSKU.length !== 0) {
    let dcId,
      doorStyle = element.doorStyle;
    if (doorStyle instanceof Map) {
      dcId = doorStyle.get('id');
    } else {
      dcId = doorStyle.id;
    }
    let skuItem = this.obj.skuArray.find(el => el.door_color_id === dcId);
    if (skuItem !== undefined) {
      type = skuItem.sku;
    }
  }

  if (rowCount > 0) {
    for (let x = 0; x < rowCount; x++) {
      splitStr.push(type.slice(lineCount * x, lineCount * (x + 1)));
    }
  }
  splitStr.push(type.slice(lineCount * rowCount));
  splitStr.forEach((el, key) => {
    txtContent.push(
      <text
        key={'text' + key}
        x="0"
        y={newDepth / 2 - 12}
        dy={16 * key}
        transform={`translate(${newWidth / 2}, ${newDepth / 2 +
          5}) scale(1,-1) rotate(${textRotation})`}
        // textLength={newWidth - 10}
        // lengthAdjust="spacingAndGlyphs"
        style={{
          fontWeight: 500,
          fontSize: '7px',
          textAnchor: 'middle',
          fill: '#FFF',
          display: 'block'
        }}
      >
        {el}
      </text>
    );
  });
  let style = {
    stroke: element.selected ? '#565658' : '#565658',
    strokeWidth: '2px',
    fill: color
  };
  // let arrow_style = { stroke: element.selected ? '#0096fd' : null, strokeWidth: "2px", fill: "#84e1ce" };

  let rendered = null;

  if (shape_svg || el_euro_shape_svg) {
    let svg_url, svg_width, svg_depth;
    if (typeof shape_svg == 'string' || typeof el_euro_shape_svg == 'string') {
      if (el_DSN === "Euro & Frameless" && el_is_euro_cds) {
        svg_url = el_euro_shape_svg;
        svg_width = newWidth;
        svg_depth = newDepth;
      } else {
        svg_url = shape_svg;
        svg_width = originalWidth;
        svg_depth = originalDepth;
      }
    } else {
      // if (el_DSN === "Euro & Frameless" && el_is_euro_cds) {
      //   // svg_url = el_euro_shape_svg.url;
      // } else {
      // }
      svg_url = shape_svg.url;
      svg_width = convert(SHAPE_SVG_WIDTH)
        .from(UNIT_INCH)
        .to(UNIT_CENTIMETER);
      svg_depth = convert(SHAPE_SVG_DEPTH)
        .from(UNIT_INCH)
        .to(UNIT_CENTIMETER);
    }

    let padding_width =
      ((padding * newWidth) / svg_width);
    let padding_depth =
      ((padding * newDepth) / svg_depth);

    rendered = (
      <g
        onMouseOver={event => {
          setHover(true);
        }}
        onMouseOut={event => {
          setHover(false);
        }}
        transform={`translate(${x},${y})`}
      >
        <g transform={`rotate(${rotation})`}>
          <g transform={`translate(${-newWidth / 2 - padding_width},${-newDepth / 2 - padding_depth})`}>
            <image
              preserveAspectRatio="none"
              style={{ pointerEvents: 'none' }}
              href={svg_url}
              width={`${newWidth + 2 * padding_width}`}
              height={`${newDepth + 2 * padding_depth}`}
              transform={`scale(1, -1)`}
              x="0"
              y={`${-newDepth - 2 * padding_depth}`}
            />
            <rect
              x={`${padding_width}`}
              y={`${padding_depth}`}
              width={`${newWidth}`}
              height={`${newDepth}`}
              visibility={element.toJS().doorStyle.doorStyles !== undefined ? (element.toJS().doorStyle.doorStyles && element.toJS().doorStyle.doorStyles.cds && element.toJS().doorStyle.doorStyles.cds.length!=0 && element.toJS().doorStyle.doorStyles.cds.filter(cd=>cd.itemID == element.getIn(['itemID']))? 'hidden': 'visible'):'hidden'}
              style={{ pointerEvents: 'all', opacity: 0.7, postion: 'relative' }}
              fill={STATUS_WARNING_LIGHT_COLOR}
              stroke={STATUS_WARNING_COLOR}
              strokeWidth="2px"
            >
            </rect>
            <g transform={`translate(${padding_width},${padding_depth})`}>
              {txtContent}
            </g>
          </g>
        </g>
      </g>
    );
  } else {
    rendered = (
      <g
        onMouseOver={event => {
          setHover(true);
        }}
        onMouseOut={event => {
          setHover(false);
        }}
        transform={`translate(${x},${y})`}
      >
        <g transform={`rotate(${rotation})`}>
          <g transform={`translate(${-newWidth / 2},${-newDepth / 2})`}>
            {newDepth > 15 ? (
              [
                <rect
                  key="base"
                  x="0"
                  y="12"
                  width={newWidth}
                  height={newDepth - 12}
                  style={style}
                />,
                <polygon
                  key="door"
                  style={style}
                  points={`0,9 ${newWidth},9 ${newWidth},6 ${newWidth -
                    5},6 ${newWidth - 5},3 ${newWidth - 2},3 ${newWidth -
                    2} 0 ${newWidth - 10} 0 ${newWidth - 10},3 ${newWidth -
                    7},3 ${newWidth - 7},6 0 6`}
                />
              ]
            ) : (
              <rect
                key="base"
                x="0"
                y="0"
                width={newWidth}
                height={newDepth}
                style={style}
              />
            )}
            {/* {<text key="text"
              x="0"
              y="0"
              // dx="10"
              // dy="10"
              transform={`translate(${newWidth / 2}, ${newDepth / 2 + 5}) scale(1,-1) rotate(${textRotation})`}
              // textLength={newWidth - 10}
              // lengthAdjust="spacingAndGlyphs"
              style={{ fontWeight:800, fontSize:'16px', textAnchor: "middle", fill:'#FFF', display: hover || element.selected? 'block' : 'none'}}>
              {element.type}
            </text>} */}
            {txtContent}
            {/* { element.selected &&
            (<g key="duplicated" style={{cursor:'pointer'}}>
              <image transform={`scale(1,-1)`} href="/assets/img/svg/duplicate_object_left.svg" x={-10} y={-newDepth/2 - 15} height="20" width="20" />
              <image transform={`scale(1,-1)`} href="/assets/img/svg/duplicate_object_right.svg" x={newWidth - 10} y={-newDepth / 2 - 15} height="20" width="20" />
            </g>)
            } */}
          </g>
        </g>
        {/* { element.selected &&
        (
          <g key="action" transform={`translate(${-newWidth / 2},${newDepth / 2})`} style={{cursor:'pointer'}}>
            <image transform={`rotate(180, 10 ,15) `} href="/assets/img/svg/2d_delete_object1.svg" x="0" y="5" height="20" width="20" data-part='remove'/>
            <image transform={`rotate(180, ${newWidth - 10} ,15)`} href="/assets/img/svg/duplicate_object_right.svg" x={newWidth-20} y="5" height="20" width="20" data-part='duplicate'/>
          </g>
        )
        } */}
      </g>
    );
  }

  return rendered;
}
// end of render 2d function /////////////////////////

export function loadTexture(url) {
  let texture = new Three.TextureLoader().load(url);
  texture.colorSpace = Three.SRGBColorSpace;
  texture.wrapS = Three.MirroredRepeatWrapping;
  texture.wrapT = Three.MirroredRepeatWrapping;
  return texture;
}

const applyTexture = (material, texture, length, height) => {
  if (texture) {
    material.map = texture;
    material.needsUpdate = true;
    material.map.wrapS = Three.RepeatWrapping;
    material.map.wrapT = Three.RepeatWrapping;
    material.map.repeat.set(length * 0.01, height * 0.01);

    if (texture.normal) {
      material.normalMap = loadTexture(texture.normal.uri);
      material.normalScale = new Vector2(
        texture.normal.normalScaleX,
        texture.normal.normalScaleY
      );
      material.normalMap.wrapS = Three.RepeatWrapping;
      material.normalMap.wrapT = Three.RepeatWrapping;
      material.normalMap.repeat.set(
        length * texture.normal.lengthRepeatScale,
        height * texture.normal.heightRepeatScale
      );
    }
  }
};

const assignUVs = geometry => {
  geometry.computeBoundingBox();

  let { min, max } = geometry.boundingBox;

  let offset = new Three.Vector3(0 - min.x, 0 - min.y, 0 - min.z);
  let range = new Three.Vector3(max.x - min.x, max.y - min.y, max.z - min.z);
  geometry.faceVertexUvs[0] = geometry.faces.map(face => {
    let v1 = geometry.vertices[face.a];
    let v2 = geometry.vertices[face.b];
    let v3 = geometry.vertices[face.c];

    return [
      new Three.Vector3(
        (v1.x + offset.x) / range.x,
        (v1.y + offset.y) / range.y,
        (v1.z + offset.z) / range.z
      ),
      new Three.Vector3(
        (v2.x + offset.x) / range.x,
        (v2.y + offset.y) / range.y,
        (v1.z + offset.z) / range.z
      ),
      new Three.Vector3(
        (v3.x + offset.x) / range.x,
        (v3.y + offset.y) / range.y,
        (v1.z + offset.z) / range.z
      )
    ];
  });

  geometry.uvsNeedUpdate = true;
};

/**
 * Render 3D Item
 * @param {Item} element Rendering item
 * @param sizeinfo Dimesion of the item
 * @param structure_json Structure of the item such as place holders and meshes, etc
 */
export function render3DItem(element, layer, scene, sizeinfo, structure_json) {
  if (element.doorStyle.constructor !== Map) {
    element = element.set('doorStyle', fromJS(element.doorStyle));
  }
  
  if (element.doorStyle.toJS().handle_gltf !== "") { // Check element has doorHandle
    for (var i = 1; i < 10; i++) {
      element = element.setIn(["doorStyle", "doorStyles", "door_handle_" + i + "_gltf"], element.doorStyle.toJS().handle_gltf);
      element = element.setIn(["doorStyle", "doorStyles", "fixed_drawer_door_handle_" + i + "_gltf"], element.doorStyle.toJS().handle_gltf);
      element = element.setIn(["doorStyle", "doorStyles", "drawer_door_handle_" + i + "_gltf"], element.doorStyle.toJS().handle_gltf);
    }
  }
  let width = { length: sizeinfo.width, unit: 'in' };
  let depth = { length: sizeinfo.depth, unit: 'in' };
  let height = { length: sizeinfo.height, unit: 'in' };
  let newWidth = convert(width.length)
    .from(width.unit)
    .to('in');
  let newDepth = convert(depth.length)
    .from(depth.unit)
    .to('in');
  let newHeight = convert(height.length)
    .from(height.unit)
    .to('in');
  let mainName = ''; // to get name structure//
  if (element.properties.get('width'))
    newWidth = element.getIn(['properties', 'width', '_length']);
  if (element.properties.get('depth'))
    newDepth = element.getIn(['properties', 'depth', '_length']);
  if (element.properties.get('height'))
    newHeight = element.getIn(['properties', 'height', '_length']);

  var structure = structure_json;
  // structure.push({name:'model', url: '/assets/model/DCM.gltf'});
  structure.model = '/assets/model/DCM.gltf';
  var placeholders = structure.placeholders;
  let doorStyles = null;
  let color = 0xffffff,
      glossness = 1,
    handleMaterial = {};
  let counterTop = element.counterTop;
  if (layer.toJS().counterTop.uri) {
  counterTop.uri = layer.toJS().counterTop.uri;
  }

  if ('name' in element.doorStyle) {
    doorStyles = new Map(element.doorStyle.doorStyles);
    color = element.doorStyle.color;
    glossness = element.doorStyle.glossness;
    handleMaterial.metalness = element.doorStyle.metalness;
    handleMaterial.roughness = element.doorStyle.roughness;
  } else if (element.doorStyle != null && element.doorStyle) {
    doorStyles = element.doorStyle.get('doorStyles');
    color = element.doorStyle.get('color');
    glossness = element.doorStyle.get('glossness');
    handleMaterial.metalness = element.doorStyle.get('metalness');
    handleMaterial.roughness = element.doorStyle.get('roughness');
  }
  if (color === undefined) color = '#ffffff';
  if (glossness === undefined) glossness = 1;
  const tempDoorStyles = doorStyles.toJS();
  let tempPlaceholders = structure.tempPlaceholders;
  var tPlaceholders = tempPlaceholders.find(el => {
    return el.id === tempDoorStyles.cabinet_door_style_id;
  });
  if (tPlaceholders !== undefined) {
    placeholders = tPlaceholders.placeholders;
    const tempStructure = {
      ...tPlaceholders.structure,
      animation: structure.animation,
      placeholders: structure.placeholders,
      tempPlaceholders: structure.tempPlaceholders,
      model: structure.model
    };
    structure = tempStructure;
  }
  let userData = structure.animation;
  // ///////////////////////////
  let onLoadItem = object => {
    var areaMaterial = new Three.MeshStandardMaterial();
    areaMaterial.side = Three.DoubleSide;
    // areaMaterial.envMap = textureCube;
    if (doorStyles != null)
      if (doorStyles.get('base') != undefined) {
        let normalMap = doorStyles.get('base');
        let interiortexture = loadTexture(normalMap);
        applyTexture(areaMaterial, interiortexture, 100, 100);
      }
    let object1 = object;
    let newAltitude = element.properties.get('altitude').get('_length');
    let newUnit = element.properties.get('altitude').get('_unit') || 'in';
    newAltitude = convert(newAltitude)
      .from(newUnit)
      .to(scene.unit);
    let _element = element.toJS();
    if(!_element.doorStyle.doorStyles.is_euro_cds) {
      object1.scale.set(
        (100 * newWidth) / sizeinfo.width,
        (100 * newHeight) / sizeinfo.height,
        (100 * newDepth) / sizeinfo.depth
      );
    } else {
      object1.scale.set( 100, 100, 100 );
    }
    // Normalize the origin of the object
    let boundingBox = GeomUtils.baseBox3FromObject(object1);
    object1.userData = boundingBox;
    object1.userData.animation = userData;

    let center = [
      (boundingBox.max.x - boundingBox.min.x) / 2 + boundingBox.min.x,
      (boundingBox.max.y - boundingBox.min.y) / 2 + boundingBox.min.y,
      (boundingBox.max.z - boundingBox.min.z) / 2 + boundingBox.min.z
    ];
    object1.position.x -= center[0];
    object1.position.y -=
      center[1] - (boundingBox.max.y - boundingBox.min.y) / 2;
    object1.position.z -= center[2];
    object1.position.y += newAltitude;

    if (element.selected) {
      // if object is selected
      // save object transform info///
      let scalevec = new Three.Vector3(
        object1.scale.x,
        object1.scale.y,
        object1.scale.z
      );
      let posVec = new Three.Vector3(
        object1.position.x,
        object1.position.y,
        object1.position.z
      );

      object.scale.set(
        (1 * newWidth) / sizeinfo.width,
        (1 * newHeight) / sizeinfo.height,
        (1 * newDepth) / sizeinfo.depth
      );
      object1.position.set(0, 0, 0);
      object1.rotation.set(0, 0, 0);

      //let box = new Three.BoxHelper(object, 0xffffff);
      //box.material.lineWidth = 5;
      //box.renderOrder = 1000;
      //box.material.depthTest = false;
      //object.add(box);
      let boundingBox = GeomUtils.baseBox3FromObject(object);
      let max = boundingBox.max;
      let min = boundingBox.min;
      let radius =
        Math.sqrt(
          (boundingBox.max.x - boundingBox.min.x) *
            (boundingBox.max.x - boundingBox.min.x) +
            (boundingBox.max.z - boundingBox.min.z) *
              (boundingBox.max.z - boundingBox.min.z)
        ) / 2;
      let moveBox = new Three.BoxGeometry(
        max.x - min.x,
        max.y - min.y,
        max.z - min.z
      );

      // translate Object
      let controlGeom = GeomUtils.controlGeom();

      // rotate Object //////////
      let rotGeom = GeomUtils.rotGeoms(radius + 0.05);
      // //////////////////////////////////

      // upwards Geometry/////////// Move up Object
      let upwardsGeom = GeomUtils.upwardsGeom();

      // vertical line - 4 lines around object//////////////////////////
      let vLineGeom = new Three.BufferGeometry();

      let vertices = [
        (max.x - min.x) / 2 + min.x, 0, max.z,
        (max.x - min.x) / 2 + min.x, 0, max.z + 1.3
      ]

      vLineGeom.setAttribute('position', new Three.BufferAttribute(new Float32Array(vertices), 3)) 

      let vLineGeom1 = new Three.BufferGeometry();

      let vertices1 = [
        (max.x - min.x) / 2 + min.x, 0, min.z,
        (max.x - min.x) / 2 + min.x, 0, min.z - 1.3
      ]   

      vLineGeom1.setAttribute('position', new Three.BufferAttribute(new Float32Array(vertices1), 3)) 

      let vLineGeom2 = new Three.BufferGeometry();

      let vertices2 = [
        max.x, 0, max.z - (max.z - min.z) / 2,
        max.x + 1.3, 0, max.z - (max.z - min.z) / 2
      ]

      vLineGeom2.setAttribute('position', new Three.BufferAttribute(new Float32Array(vertices2), 3)) 

      let vLineGeom3 = new Three.BufferGeometry();

      let vertices3 = [
        min.x, 0, max.z - (max.z - min.z) / 2,
        min.x - 1.3, 0, max.z - (max.z - min.z) / 2
      ]

      vLineGeom3.setAttribute('position', new Three.BufferAttribute(new Float32Array(vertices3), 3)) 
      // ///////////////////////////////////////

      // set names of transform object
      let rotFillObj = new Three.Mesh(
        rotGeom.rotFill,
        new Three.MeshPhongMaterial({
          color: new Three.Color(0x000000).convertLinearToSRGB(),
          side: Three.DoubleSide,
          colorWrite: true
        })
      );
      let rotStrokeObj = new Three.Line(
        rotGeom.rotStroke,
        new Three.LineBasicMaterial({ color: new Three.Color(0xffffff).convertLinearToSRGB() , colorWrite: true })
      );
      rotFillObj.name = 'rotate';

      let upObj = new Three.Mesh(
        upwardsGeom,
        new Three.MeshBasicMaterial({ color: new Three.Color(0xffffff).convertLinearToSRGB(), side: Three.DoubleSide })
      );
      upObj.name = 'transUp';

      let mBox = new Three.Mesh(
        moveBox,
        new Three.MeshBasicMaterial({
          color:  new Three.Color(0xdd6699).convertLinearToSRGB(),
          side: Three.DoubleSide,
          transparent: true,
          opacity: 0.4
        })
      );

      let color = new Three.Color(SHADE_DARK_PURPLE_COLOR).convertLinearToSRGB();
      let vLine = new Three.Line(
        vLineGeom,
        new Three.LineBasicMaterial({ color })
      );
      let vLine1 = new Three.Line(
        vLineGeom1,
        new Three.LineBasicMaterial({ color })
      );
      let vLine2 = new Three.Line(
        vLineGeom2,
        new Three.LineBasicMaterial({ color })
      );
      let vLine3 = new Three.Line(
        vLineGeom3,
        new Three.LineBasicMaterial({ color })
      );
      vLine.renderOrder = 1;
      vLine1.renderOrder = 1;
      vLine2.renderOrder = 1;
      vLine3.renderOrder = 1;
      vLine.material.transparent = true;
      vLine1.material.transparent = true;
      vLine2.material.transparent = true;
      vLine3.material.transparent = true;
      vLine.material.depthTest = false;
      vLine1.material.depthTest = false;
      vLine2.material.depthTest = false;
      vLine3.material.depthTest = false;
      // translate vector to center of object
      let uVec = new Three.Vector3(
        -posVec.x / scalevec.x,
        -posVec.y / scalevec.y,
        -posVec.z / scalevec.z
      );

      vLine.translateY(0.1);
      vLine1.translateY(0.1);
      vLine2.translateY(0.1);
      vLine3.translateY(0.1);

      //rotObj.translateOnAxis(uVec, 1);
      upObj.translateOnAxis(uVec, 1);
      upObj.translateY(max.y - min.y);

      mBox.name = 'TransformBox';
      mBox.translateOnAxis(
        new Three.Vector3(uVec.x, uVec.y + (max.y - min.y) / 2, uVec.z),
        1
      );
      mBox.scale.set(1.01, 1.01, 1.01);

      // other side rotate object
      let rotFillObj1 = rotFillObj.clone();
      let rotStrokeObj1 = rotStrokeObj.clone();
      rotFillObj1.rotateY(Math.PI);
      rotStrokeObj1.rotateY(Math.PI);
      rotFillObj.translateY(0.1);
      rotFillObj1.translateY(0.1);
      rotStrokeObj.translateY(0.1);
      rotStrokeObj1.translateY(0.1);

      // assets Objects group includes rotate objects...
      let asrtObj = new Three.Group();
      // asrtObj.add(rotFillObj);
      // asrtObj.add(rotFillObj1);
      // asrtObj.add(rotStrokeObj);
      // asrtObj.add(rotStrokeObj1);
      //asrtObj.add(upObj);
      asrtObj.add(vLine);
      asrtObj.add(vLine1);
      asrtObj.add(vLine2);
      asrtObj.add(vLine3);
      mBox.visible = false;
      asrtObj.add(mBox);
      asrtObj.scale.set(1 / object.scale.x, object.scale.y, 1 / object.scale.z);
      //asrtObj.translateY(newAltitude / scalevec.y);
      asrtObj.name = 'TransformGizmo';
      // add assets Objects Group
      object1.add(asrtObj);

      // recover objects transform
      object1.position.x = posVec.x;
      object1.position.y = posVec.y;
      object1.position.z = posVec.z;
      object1.scale.set(scalevec.x, scalevec.y, scalevec.z);
      setTimeout(() => {
        SceneCreator.getDistances(layer);
      }, 100);
    }

    let flip_doorhandle = element.properties.get('flip_doorhandle');
    if (flip_doorhandle) {
      SceneCreator.updateDoorHandleMesh(element, object1, true);
    }

    object1.traverse(obj => {
      if (obj.type === OBJTYPE_MESH) {
        const { name } = obj;
        if (name.match(/_door_.*_glass_/)) {
          // texture = loadTexture('/assets/img/texture/glass.jpg');
          const material = new Three.MeshPhysicalMaterial({
            roughness: 0,
            transmission: 1,
            thickness: 0.5, // Add refraction!
            transparency: 0.8
          });
          obj.material = material;
        } else if (name.startsWith('sink_')) {
          // texture = loadTexture('/assets/img/texture/steel.jpg');

          let material;
          // Get color from name
          if (name.includes('black') || name.includes('white')) {
            let color;
            if (name.includes('black')) {
              color = new Three.Color(0x555555).convertLinearToSRGB();
            } else {
              color = 0xffffff;
            }
            material = new Three.MeshPhysicalMaterial({
              roughness: 0.5,
              metalness: 0,
              // transmission: 1,
              transparent: true,
              opacity : 1,
              thickness: 0.5, // Add refraction!
              // transparency: 0.8,
              color: color,
              side: Three.DoubleSide
            });
          } else {
            // if (name.includes('chrome')) {
            material = new Three.MeshPhysicalMaterial({
              roughness: 0.2,
              metalness: 1,
              reflectivity: 0.5,
              color: new Three.Color(0xdddddd).convertLinearToSRGB()
            });
          }
          obj.material = material;
        }
      }
    });

    return object1;
  };
  // keys in structure
  let keys = Object.keys(structure);
  // if exist in cached Objects
  if (
    element.type +
      color +
      'doorStyle' +
      JSON.stringify(doorStyles.toJS()) +
      element.counterTop.uri in
    cachedObject
  ) {
    let objGroup = cachedObject[
      element.type + color + 'doorStyle' + JSON.stringify(doorStyles.toJS())
    ].clone();
    return Promise.resolve(onLoadItem(objGroup.clone()));
  }

  // base Object/////
  let objGroup = null;

  let loadGLTFs = function(i) {
    if (keys[i] === 'animation') {
      // if animation info
      i++;
      return loadGLTFs(i);
    }
    if (keys[i] === 'placeholders') {
      // if placeholders group
      i++;
      return loadGLTFs(i);
    }
    if (i === keys.length) {
      // if end of keys
      cachedObject[
        element.type + color + 'doorStyle' + JSON.stringify(doorStyles.toJS())
      ] = objGroup.clone(); //register to cachedObject
      return onLoadItem(
        cachedObject[
          element.type + color + 'doorStyle' + JSON.stringify(doorStyles.toJS())
        ].clone()
      );
    }
    if (keys[i] === 'base') {
      // if base Objects
      i++;
      return loadGLTFs(i);
    }

    let phsArray = [];
    let placeholderStructure = placeholders[keys[i]];
    if (placeholderStructure == undefined || placeholderStructure.length == 0) {
      i++;
      return loadGLTFs(i);
    }

    for (let j = 0; j < placeholderStructure.length; j++) {
      let phData = placeholderStructure[j];
      let phs = phData.split('/');

      let temp = phData.split('/');
      // placeholder remake////////////////
      for (let k = 0; k < phs.length; k++) {
        if (phs[k] in placeholders) {
          let placeholderphs = placeholders[phs[k]];
          let key = placeholderStructure.length / placeholderphs.length;
          phs[k] = placeholderphs[Math.floor(j / key)];
          let splitedData = phs[k].split('/');

          if (splitedData.length > 1) {
            phs[k] = splitedData[splitedData.length - 1];

            for (let m = splitedData.length - 2; m >= 0; m--) {
              phs.unshift(splitedData[m]);
              temp.unshift(splitedData[m]);
            }
          }

          k = -1;
          continue;
        }
        if (phs[k].indexOf('ph') == -1) {
          let url = structure[temp[k - 1]];

          if (
            temp[k - 1] +
              '_doorStyle' +
              element.type +
              'doorStyle' +
              JSON.stringify(doorStyles.toJS()) in
            structure
          ) {
            if (
              structure[
                temp[k - 1] +
                  '_doorStyle' +
                  element.type +
                  'doorStyle' +
                  JSON.stringify(doorStyles.toJS())
              ] != null
            ) {
              url =
                structure[
                  temp[k - 1] +
                    '_doorStyle' +
                    element.type +
                    'doorStyle' +
                    JSON.stringify(doorStyles.toJS())
                ];
            }
          }
          if (typeof url == Array) url = url[0];

          let uData = url.split('/');
          uData = uData[uData.length - 1];
          uData = uData.slice(0, -5);
          let datas = uData.split('_');
          uData = datas[1];

          for (let i = 2; i < datas.length; i++) {
            uData += '_';
            uData += datas[i];
          }

          uData = mainName.replace('main', uData);
          phs[k] = 'ph_' + uData + '_' + phs[k];
        }
      }
      phsArray.push(phs);
    }
    // ///////////////////////////////////
    let url = structure[keys[i]];
    let normalMap = '';
    let urlData = url.split('/');
    for (let j = 0; j < element.submodule.size; j++) {
      let replaceUrlData = element.submodule.get(j).split('/');
      if (urlData.includes(replaceUrlData[replaceUrlData.length - 2])) {
        url = element.submodule.get(j);
        break;
      }
    }

    for (let j = 0; j < element.normalMap.size; j++) {
      let normalMapData = element.normalMap.get(j).split('/');
      if (urlData.includes(normalMapData[normalMapData.length - 2])) {
        normalMap = element.normalMap.get(j);
        break;
      }
    }

    // replace submodule gltf file
    // if (placeholderTree.length > 0) {
    if (phsArray.length > 0) {
      // let loadUrl = dirName + url;
      let loadUrl = url;

      if (doorStyles.get(keys[i] + '_gltf') != undefined) {
        loadUrl = doorStyles.get(keys[i] + '_gltf');
        structure[
          keys[i] +
            '_doorStyle' +
            element.type +
            'doorStyle' +
            JSON.stringify(doorStyles.toJS())
        ] = loadUrl;
      } else {
        structure[
          keys[i] +
            '_doorStyle' +
            element.type +
            'doorStyle' +
            JSON.stringify(doorStyles.toJS())
        ] = null;
      }
      return loadGLTF(loadUrl)
        .then(
          object => {
            if (normalMap !== '') {
              let normalUrl =
                normalMap.split('.')[0] + '-normal.' + normalMap.split('.')[1];
              let t = loadTexture(normalMap);
              let m = loadTexture(normalUrl);
              let mat2 = new Three.MeshStandardMaterial({
                metalness: glossness === 1 ? params.metalness : params.metalness_glossy,
                roughness: glossness || params.roughness
              });
              mat2.map = t;
              mat2.normalMap = m;
              // mat2.envMap = textureCube;
              for (let j = 0; j < object.children.length; j++) {
                if (object.children[j].type === OBJTYPE_MESH) {
                  object.children[j].material = mat2;
                  object.children[j].receiveShadow = true;
                }
              }
            }

            // set Door Style////
            if (doorStyles != null)
              if (doorStyles.get(keys[i]) != undefined) {
                // let normalMap = "catalog/items/doorstyle/" + doorStyles.get(keys[i]);
                let normalMap = doorStyles.get(keys[i]);
                let mat2;
                if (normalMap === '') {
                  const examplecolor = new Three.Color(
                    parseInt(color.slice(1), 16)
                  ).convertLinearToSRGB();
                  mat2 = new Three.MeshStandardMaterial({
                    color: examplecolor,
                    metalness: glossness === 1 ? params.metalness : params.metalness_glossy,
                    roughness: glossness || params.roughness
                  });
                } else {
                  let t = loadTexture(normalMap);
                  mat2 = new Three.MeshStandardMaterial({
                    // NOTE : this is for cabinets (wood) frontface
                    metalness: 0.1,
                    roughness: 0.5
                    // metalness: glossness === 1 ? params.metalness : params.metalness_glossy,
                    // roughness: glossness || params.roughness
                  }); 
                  mat2.map = t;
                }
                // mat2.envMap = textureCube;
                for (let j = 0; j < object.children.length; j++) {
                  if (object.children[j].type === OBJTYPE_MESH) {
                    object.children[j].material = mat2;
                    object.children[j].receiveShadow = true;
                    object.children[j].castShadow = true;
                   !object.children[j].name.includes('handle') && addEdgesToMesh(object.children[j]);
                  } else if (
                    !object.children[j].name.startsWith('ph_') &&
                    object.children[j].type === OBJTYPE_GROUP
                  ) {
                    object.children[j].traverse(prim => {
                      prim.material = mat2;
                      prim.receiveShadow = true;
                    });
                  }
                }
              } else {
                let mat2 = new Three.MeshStandardMaterial({
                  metalness: glossness === 1 ? params.metalness : params.metalness_glossy,
                  roughness: glossness || params.roughness
                });
                // mat2.envMap = textureCube;
                for (let j = 0; j < object.children.length; j++) {
                  if (object.children[j].type === OBJTYPE_MESH) {
                    object.children[j].material = mat2;
                    object.children[j].receiveShadow = true;
                  }
                }
              }

            for (let i = 0; i < phsArray.length; i++) {
              let phs = phsArray[i];
              let parent = objGroup;
              for (let j = 0; j < phs.length; j++) {
                let placeholder = phs[j];
                for (let k = 0; k < parent?.children.length; k++) {
                  if (j != phs.length - 1) {
                    if (parent.children[k].name == placeholder) {
                      parent = parent.children[k].children[0];
                      break;
                    }
                  } else {
                    if (parent.children[k].name == placeholder) {
                      var tmp = object.clone();
                      if (
                        placeholder.includes('drawer_door') &&
                        placeholder.includes('_handle')
                      ) {
                        tmp.rotateZ(Math.PI / 2);
                      }
                      if (placeholder.includes('_handle') && tmp.children[0].type === OBJTYPE_MESH) {
                        // NOTE: change metalness of handle 
                        tmp.children[0].material.metalness = 1;
                          // handleMaterial.metalness || 0.2;
                        tmp.children[0].material.roughness =
                          handleMaterial.roughness || 0.1;
                        //tmp.children[0].material.map = loadTexture('catalog/areas/area/textures/grass.jpg');
                      }
                      parent.children[k].add(tmp);
                    }
                  }
                }
              }
            }
          },
          reason => {
            console.log('loadGLTF failed for reason:', reason);
          }
        )
        .then(() => {
          i++;
          return loadGLTFs(i);
        });
    }
  };
  return loadGLTF(structure['base'])
    .then(
      object => {
        object.name = 'MainObject';
        object.receiveShadow = true;
        objGroup = object;
        if (doorStyles != null)
          if (doorStyles.get('base') != undefined) {
            let normalMap = doorStyles.get('base');
            if (counterTop.uri === undefined) {
              try {
                counterTop = counterTop.toJS();
              } catch (error) {
                console.log(error);
              }
            }
            if (
              counterTop.uri === undefined &&
              layer.toJS().counterTop.uri !== undefined
            ) {
              counterTop.uri = layer.toJS().counterTop.uri;
            }
            let countTopMap = counterTop.uri;
            let interiorMap = doorStyles.get('interior');
            let countT = loadTexture(countTopMap);
            countT.wrapS = Three.RepeatWrapping;
            countT.wrapT = Three.RepeatWrapping;
            countT.repeat.set(1, 1);
            const examplecolor = new Three.Color(parseInt(color.slice(1), 16)).convertLinearToSRGB();
            let mat2 = null,
              mat3 = null,
              mat4 = null;

            if (normalMap === '') {
              mat2 = new Three.MeshStandardMaterial({
                color: examplecolor,
                metalness: glossness === 1 ? params.metalness : params.metalness_glossy,
                roughness: glossness || params.roughness
              });
            } else {
              mat2 = new Three.MeshStandardMaterial({
                // TODO: changes in metalness and roughness of base_main (cabinet wood)
                metalness: 0.1,
                roughness: 0.5
                // metalness: glossness === 1 ? params.metalness : params.metalness_glossy,
                // roughness: glossness || params.roughness
              });
            }
            // mat2.envMap = textureCube;

            if (normalMap !== '') {
              let t = loadTexture(normalMap);
              mat2.map = t;
            }

            if (normalMap === '') {
              mat3 = new Three.MeshStandardMaterial({
                // color: examplecolor,
                metalness: counterTop.metalness,
                roughness: counterTop.roughness
              });
            } else {
              mat3 = new Three.MeshStandardMaterial({
                // metalness: counterTop.metalness,
                // roughness: counterTop.roughness
                metalness: 0.3,
                roughness: 0.8,
              });
            }
            mat3.map = countT;
            // mat3.envMap = textureCube;
            mat4 = new Three.MeshStandardMaterial({
              metalness: params.metalness,
              roughness: params.roughness
            });
            mat4.map = loadTexture(interiorMap);
            for (let j = 0; j < object.children.length; j++) {
              if (object.children[j].name.includes('main')) {
              }
              if (object.children[j].name.includes('countertop')) {
                object.children[j].material = mat3;
                object.children[j].receiveShadow = true;
                object.children[j].castShadow = true;
                addEdgesToMesh(object.children[j]);
              } else if (object.children[j].name.includes('_interior_')) {
                object.children[j].material = mat4;
              } else if (object.children[j].type === OBJTYPE_MESH) {
                object.children[j].material = mat2;
                object.children[j].receiveShadow = true;
                object.children[j].castShadow = true;
              }
            }
          }
      },
      reason => {
        console.log('loadGLTF failed for reason:', reason);
        objGroup = GeomUtils.emptyBoxHolder(newWidth, newHeight, newDepth);
      }
    )
    .then(() => {
      return loadGLTFs(0);
    });
}

// render 3d appliance function ////////////////////////////////
export function render3DApplianceItem(
  element,
  layer,
  scene,
  sizeinfo,
  structure_json
) {
  let structure = structure_json;
  let { applianceMaterial } = element;
  if (applianceMaterial.metalness == undefined)
    applianceMaterial = applianceMaterial.toJS();
  let onLoadItem = object => {
    let newAltitude = element.properties.get('altitude').get('_length');
    let newUnit = element.properties.get('altitude').get('_unit') || 'in';
    newAltitude = convert(newAltitude)
      .from(newUnit)
      .to(scene.unit);

    let newWidth = element.properties.get('width').get('_length');
    let newWidthUnit = element.properties.get('width').get('_unit') || 'in';
    newWidth = convert(newWidth)
      .from(newWidthUnit)
      .to('in');

    let newHeight = element.properties.get('height').get('_length');
    let newHeightUnit = element.properties.get('height').get('_unit') || 'in';
    newHeight = convert(newHeight)
      .from(newHeightUnit)
      .to('in');

    let newDepth = element.properties.get('depth').get('_length');
    let newDepthUnit = element.properties.get('depth').get('_unit') || 'in';
    newDepth = convert(newDepth)
      .from(newDepthUnit)
      .to('in');

    object.scale.set(
      (100 * newWidth) / sizeinfo.width,
      (100 * newHeight) / sizeinfo.height,
      (100 * newDepth) / sizeinfo.depth
    );
    // Normalize the origin of the object
    let boundingBox = new Three.Box3().setFromObject(object);
    object.userData = boundingBox;

    let center = [
      (boundingBox.max.x - boundingBox.min.x) / 2 + boundingBox.min.x,
      (boundingBox.max.y - boundingBox.min.y) / 2 + boundingBox.min.y,
      (boundingBox.max.z - boundingBox.min.z) / 2 + boundingBox.min.z
    ];
    object.position.x -= center[0];
    object.position.y -=
      center[1] - (boundingBox.max.y - boundingBox.min.y) / 2;
    object.position.z -= center[2];
    object.position.y += newAltitude;

    object.traverse(obj => {
      if (obj.type === OBJTYPE_MESH) {
        const { name } = obj;
        let texture,
          textureLoader = new Three.TextureLoader();
        if (name.includes('_black')) {
          obj.material.roughness = 0.4;
          obj.material.metalness = 1.0;
          obj.material.color = new Three.Color(0, 0, 0);
          obj.castShadow = true;
          obj.receiveShadow = true;
          return object;
        } else if (name.includes('_wood')) {
          texture = loadTexture('/assets/img/texture/wood.jpg');
        } else if (name.includes('_glass')) {
          // texture = loadTexture('/assets/img/texture/glass.jpg');
          const material = new Three.MeshPhysicalMaterial({
            transparent: true,
            opacity: 0.5,
            roughness: 0,
            transmission: 1,
            thickness: 0.5, // Add refraction!
            transparency: 0.8
          });
          obj.material = material;
          obj.castShadow = true;
          obj.receiveShadow = true;
          return object;
        } else if (name.includes('_steel')) {
          // texture = loadTexture('/assets/img/texture/steel.jpg');
          const material = new Three.MeshPhysicalMaterial({
            roughness: 0.2,
            metalness: 0.5,
            reflectivity: 0.5,
            color: new Three.Color(0xdddddd).convertLinearToSRGB()
          });
          obj.material = material;
          obj.castShadow = true;
          obj.receiveShadow = true;
          return object;
        }

        let mat = new Three.MeshStandardMaterial({
          metalness: 0.1,
          roughness: 0.9,
          map: texture
        });
        obj.material = mat;
      }
    });

    if (element.selected) {
      // if object is selected
      // save object transform info///
      let scalevec = new Three.Vector3(
        object.scale.x,
        object.scale.y,
        object.scale.z
      );
      let posVec = new Three.Vector3(
        object.position.x,
        object.position.y,
        object.position.z
      );

      object.scale.set(
        (1 * newWidth) / sizeinfo.width,
        (1 * newHeight) / sizeinfo.height,
        (1 * newDepth) / sizeinfo.depth
      );
      object.position.set(0, 0, 0);
      object.rotation.set(0, 0, 0);
      let boundingBox = new Three.Box3().setFromObject(object);
      let max = boundingBox.max;
      let min = boundingBox.min;
      let radius =
        Math.sqrt(
          (boundingBox.max.x - boundingBox.min.x) *
            (boundingBox.max.x - boundingBox.min.x) +
            (boundingBox.max.z - boundingBox.min.z) *
              (boundingBox.max.z - boundingBox.min.z)
        ) / 2;
      let moveBox = new Three.BoxGeometry(
        max.x - min.x,
        max.y - min.y,
        max.z - min.z
      );

      // translate Object
      let controlGeom = GeomUtils.controlGeom();
      // ////////////////////////

      // rotate Object //////////
      let rotGeom = GeomUtils.rotGeoms(radius + 0.05);
      // //////////////////////////////////

      // upwards Geometry/////////// Move up Object
      let upwardsGeom = GeomUtils.upwardsGeom();
      // ///////////////////////////////////////

      // vertical line - 4 lines around object//////////////////////////
      let vLineGeom = new Three.BufferGeometry();
      let vertices = [
        (max.x - min.x) / 2 + min.x, 0, min.z,
        (max.x - min.x) / 2 + min.x, 0, min.z + 1.3
      ]   

      vLineGeom.setAttribute('position', new Three.BufferAttribute(new Float32Array(vertices), 3))

      let vLineGeom1 = new Three.BufferGeometry();

      let vertices1 = [
        (max.x - min.x) / 2 + min.x, 0, min.z,
        (max.x - min.x) / 2 + min.x, 0, min.z - 1.3
      ]   

      vLineGeom1.setAttribute('position', new Three.BufferAttribute(new Float32Array(vertices1), 3))

      let vLineGeom2 = new Three.BufferGeometry();
      let vertices2 = [
        max.x, 0, max.z - (max.z - min.z) / 2,
        max.x + 1.3, 0, max.z - (max.z - min.z) / 2
      ]   

      vLineGeom1.setAttribute('position', new Three.BufferAttribute(new Float32Array(vertices2), 3))

      let vLineGeom3 = new Three.BufferGeometry();
      let vertices3 = [
        min.x, 0, max.z - (max.z - min.z) / 2,
        min.x - 1.3, 0, max.z - (max.z - min.z) / 2
      ]   

      vLineGeom1.setAttribute('position', new Three.BufferAttribute(new Float32Array(vertices3), 3))
      // ///////////////////////////////////////

      // set names of transform object
      let rotFillObj = new Three.Mesh(
        rotGeom.rotFill,
        new Three.MeshPhongMaterial({
          color: 0x000000,
          side: Three.DoubleSide,
          colorWrite: true
        })
      );
      let rotStrokeObj = new Three.Line(
        rotGeom.rotStroke,
        new Three.LineBasicMaterial({ color: 0xffffff, colorWrite: true })
      );
      rotFillObj.name = 'rotate';

      let upObj = new Three.Mesh(
        upwardsGeom,
        new Three.MeshBasicMaterial({ color: 0xffffff, side: Three.DoubleSide })
      );
      upObj.name = 'transUp';

      let mBox = new Three.Mesh(
        moveBox,
        new Three.MeshBasicMaterial({
          color: new Three.Color(0xdd6699).convertLinearToSRGB(),
          side: Three.DoubleSide,
          transparent: true,
          opacity: 0.4
        })
      );

      let color = new Three.Color(ARROW_COLOR).convertLinearToSRGB();
      let vLine = new Three.Line(
        vLineGeom,
        new Three.LineBasicMaterial({ color })
      );
      let vLine1 = new Three.Line(
        vLineGeom1,
        new Three.LineBasicMaterial({ color })
      );
      let vLine2 = new Three.Line(
        vLineGeom2,
        new Three.LineBasicMaterial({ color })
      );
      let vLine3 = new Three.Line(
        vLineGeom3,
        new Three.LineBasicMaterial({ color })
      );

      vLine.renderOrder = 1;
      vLine1.renderOrder = 1;
      vLine2.renderOrder = 1;
      vLine3.renderOrder = 1;
      vLine.material.transparent = true;
      vLine1.material.transparent = true;
      vLine2.material.transparent = true;
      vLine3.material.transparent = true;
      vLine.material.depthTest = false;
      vLine1.material.depthTest = false;
      vLine2.material.depthTest = false;
      vLine3.material.depthTest = false;
      // translate vector to center of object
      let uVec = new Three.Vector3(
        -posVec.x / scalevec.x,
        -posVec.y / scalevec.y,
        -posVec.z / scalevec.z
      );

      vLine.translateY(0.1);
      vLine1.translateY(0.1);
      vLine2.translateY(0.1);
      vLine3.translateY(0.1);

      upObj.translateOnAxis(uVec, 1);
      upObj.translateY(max.y - min.y + 0.05);

      mBox.name = 'TransformBox';
      mBox.translateOnAxis(
        new Three.Vector3(uVec.x, uVec.y + (max.y - min.y) / 2, uVec.z),
        1
      );
      mBox.scale.set(1.01, 1.01, 1.01);

      // other side rotate object
      let rotFillObj1 = rotFillObj.clone();
      let rotStrokeObj1 = rotStrokeObj.clone();
      rotFillObj1.rotateY(Math.PI);
      rotStrokeObj1.rotateY(Math.PI);
      rotFillObj.translateY(0.1);
      rotFillObj1.translateY(0.1);
      rotStrokeObj.translateY(0.1);
      rotStrokeObj1.translateY(0.1);

      // assets Objects group includes rotate objects...
      let asrtObj = new Three.Group();
      // asrtObj.add(rotFillObj);
      // asrtObj.add(rotFillObj1);
      // asrtObj.add(rotStrokeObj);
      // asrtObj.add(rotStrokeObj1);
      asrtObj.add(vLine);
      asrtObj.add(vLine1);
      asrtObj.add(vLine2);
      asrtObj.add(vLine3);
      mBox.visible = false;
      asrtObj.add(mBox);
      asrtObj.scale.set(1 / object.scale.x, object.scale.y, 1 / object.scale.z);
      // asrtObj.translateY(newAltitude / scalevec.y);
      asrtObj.name = 'TransformGizmo';
      // add assets Objects Group
      object.add(asrtObj);

      // recover objects transform
      object.position.x = posVec.x;
      object.position.y = posVec.y;
      object.position.z = posVec.z;
      object.scale.set(scalevec.x, scalevec.y, scalevec.z);
    }

    return object;
  };

  // keys in structure
  let keys = Object.keys(structure);

  // if exist in cached Objects
  if (element.type in cachedObject) {
    let objGroup = cachedObject[element.type].clone();
    return Promise.resolve(onLoadItem(objGroup.clone()));
  }

  // base Object/////
  let objGroup = null;

  let loadGLTFs = function(i) {
    if (i === keys.length) {
      // if end of keys
      cachedObject[element.type] = objGroup.clone(); //register to cachedObject
      return onLoadItem(cachedObject[element.type].clone());
    }
    if (keys[i] === 'base') {
      // if base Objects
      i++;
      return loadGLTFs(i);
    }
  };

  // load base to start //
  // return loadGLTF(dirName + structure["base"])
  return loadGLTF(structure['base'])
    .then(
      object => {
        // let textureURL = `${API_SERVER_URL}/uploads/assets/default/steel.jpg`;
        // let texture = loadTexture(textureURL);
        // texture.wrapS = Three.MirroredRepeatWrapping;
        // texture.wrapT = Three.MirroredRepeatWrapping;

        object.name = 'MainObject';
        // NOTE: changed appliance emissive color to black
        let mat2 = new Three.MeshStandardMaterial({
          emissive: new Three.Color(0x0d0d0d).convertLinearToSRGB(),
          metalness: applianceMaterial.metalness,
          roughness: applianceMaterial.roughness
        });
        // mat2.envMap = textureCube;
        object.material = mat2;

        for (let j = 0; j < object.children.length; j++) {
          if (object.children[j].type === OBJTYPE_MESH) {
            object.children[j].material = mat2;
            object.children[j].receiveShadow = true;
            object.children[j].castShadow = true;// change
          }
        }
        objGroup = object;
      },
      reason => {
        console.log('loadGLTF failed for reason:', reason);
        objGroup = GeomUtils.emptyBoxHolder(newWidth, newHeight, newDepth);
      }
    )
    .then(() => {
      return loadGLTFs(0);
    });
}
// render 3d appliance function ////////////////////////////////
export function render3DLightingItem(
  element,
  layer,
  scene,
  sizeinfo,
  structure_json
) {
  let structure = structure_json;
  let onLoadItem = object => {
    let width = { length: sizeinfo.width, unit: 'in' };
    let depth = { length: sizeinfo.depth, unit: 'in' };
    let height = { length: sizeinfo.height, unit: 'in' };
    let newWidth = convert(width.length)
      .from(width.unit)
      .to('cm');
    let newDepth = convert(depth.length)
      .from(depth.unit)
      .to('cm');
    let newHeight = convert(height.length)
      .from(height.unit)
      .to('cm');

    let newAltitude = element.properties.get('altitude').get('_length');
    let newUnit = element.properties.get('altitude').get('_unit') || 'in';
    newAltitude = convert(newAltitude)
      .from(newUnit)
      .to(scene.unit);

    //object.scale.set(newWidth, newHeight, newDepth);
    object.scale.set(100, 100, 100);
    // Normalize the origin of the object
    let boundingBox = new Three.Box3().setFromObject(object);
    object.userData = boundingBox;

    let objectHeight = boundingBox.max.y - boundingBox.min.y;
    let objectYPos = boundingBox.min.y;

    let center = [
      (boundingBox.max.x - boundingBox.min.x) / 2 + boundingBox.min.x,
      (boundingBox.max.y - boundingBox.min.y) / 2 + boundingBox.min.y,
      (boundingBox.max.z - boundingBox.min.z) / 2 + boundingBox.min.z
    ];

    object.position.x = center[0];
    object.position.y = newHeight + newAltitude - boundingBox.max.y;
    object.position.z = center[2];

    if (element.selected) {
      // if object is selected
      // save object transform info///
      let scalevec = new Three.Vector3(
        object.scale.x,
        object.scale.y,
        object.scale.z
      );
      let posVec = new Three.Vector3(
        object.position.x,
        object.position.y,
        object.position.z
      );

      object.scale.set(1, 1, 1);
      object.position.set(0, 0, 0);
      object.rotation.set(0, 0, 0);

      //let box = new Three.BoxHelper(object, 0xffffff);
      //box.material.lineWidth = 5;
      //box.renderOrder = 1000;
      //box.material.depthTest = false;
      //object.add(box);
      let boundingBox = new Three.Box3().setFromObject(object);
      let max = boundingBox.max;
      let min = boundingBox.min;
      let radius =
        Math.sqrt(
          (boundingBox.max.x - boundingBox.min.x) *
            (boundingBox.max.x - boundingBox.min.x) +
            (boundingBox.max.z - boundingBox.min.z) *
              (boundingBox.max.z - boundingBox.min.z)
        ) / 2;
      let moveBox = new Three.BoxGeometry(
        max.x - min.x,
        max.y - min.y,
        max.z - min.z
      );

      // translate Object
      let controlGeom = GeomUtils.controlGeom();

      // rotate Object //////////
      let rotGeom = GeomUtils.rotGeoms(radius + 0.05);
      // //////////////////////////////////

      // upwards Geometry/////////// Move up Object
      let upwardsGeom = GeomUtils.upwardsGeom();

      // vertical line - 4 lines around object//////////////////////////
      let vLineGeom = new Three.BufferGeometry(); 
      let vertices = [
        (max.x - min.x) / 2 + min.x, 0, min.z,
        (max.x - min.x) / 2 + min.x, 0, min.z + 1.3
      ]   

      vLineGeom.setAttribute('position', new Three.BufferAttribute(new Float32Array(vertices), 3))

      let vLineGeom1 = new Three.BufferGeometry();
      let vertices1 = [
        (max.x - min.x) / 2 + min.x, 0, min.z,
        (max.x - min.x) / 2 + min.x, 0, min.z - 1.3
      ]

      vLineGeom1.setAttribute('position', new Three.BufferAttribute(new Float32Array(vertices1), 3))

      let vLineGeom2 = new Three.BufferGeometry();
      let vertices2 = [
        min.x, 0, max.z - (max.z - min.z) / 2,
        min.x + 1.3, 0, max.z - (max.z - min.z) / 2
      ]

      vLineGeom2.setAttribute('position', new Three.BufferAttribute(new Float32Array(vertices2), 3))

      let vLineGeom3 = new Three.BufferGeometry();
      let vertices3 = [
        min.x, 0, max.z - (max.z - min.z) / 2,
        min.x - 1.3, 0, max.z - (max.z - min.z) / 2
      ]

      vLineGeom3.setAttribute('position', new Three.BufferAttribute(new Float32Array(vertices3), 3))
      // ///////////////////////////////////////

      // set names of transform object
      let rotFillObj = new Three.Mesh(
        rotGeom.rotFill,
        new Three.MeshPhongMaterial({
          color: 0x000000,
          side: Three.DoubleSide,
          colorWrite: true
        })
      );
      let rotStrokeObj = new Three.Line(
        rotGeom.rotStroke,
        new Three.LineBasicMaterial({ color: 0xffffff, colorWrite: true })
      );
      rotFillObj.name = 'rotate';

      let upObj = new Three.Mesh(
        upwardsGeom,
        new Three.MeshBasicMaterial({ color: 0xffffff, side: Three.DoubleSide })
      );
      upObj.name = 'transUp';

      let mBox = new Three.Mesh(
        moveBox,
        new Three.MeshBasicMaterial({
          color: 0xdd6699,
          side: Three.DoubleSide,
          transparent: true,
          opacity: 0.4
        })
      );

      let color = new Three.Color(ARROW_COLOR).convertLinearToSRGB();
      let vLine = new Three.Line(
        vLineGeom,
        new Three.LineBasicMaterial({ color })
      );
      let vLine1 = new Three.Line(
        vLineGeom1,
        new Three.LineBasicMaterial({ color })
      );
      let vLine2 = new Three.Line(
        vLineGeom2,
        new Three.LineBasicMaterial({ color })
      );
      let vLine3 = new Three.Line(
        vLineGeom3,
        new Three.LineBasicMaterial({ color })
      );
      vLine.renderOrder = 1;
      vLine1.renderOrder = 1;
      vLine2.renderOrder = 1;
      vLine3.renderOrder = 1;
      vLine.material.transparent = true;
      vLine1.material.transparent = true;
      vLine2.material.transparent = true;
      vLine3.material.transparent = true;
      vLine.material.depthTest = false;
      vLine1.material.depthTest = false;
      vLine2.material.depthTest = false;
      vLine3.material.depthTest = false;
      // translate vector to center of object
      let uVec = new Three.Vector3(
        -posVec.x / scalevec.x,
        -posVec.y / scalevec.y,
        -posVec.z / scalevec.z
      );

      vLine.translateY(1.6);
      vLine1.translateY(1.6);
      vLine2.translateY(1.6);
      vLine3.translateY(1.6);

      //rotObj.translateOnAxis(uVec, 1);
      upObj.translateOnAxis(uVec, 1);
      upObj.translateY(max.y - min.y);

      mBox.name = 'TransformBox';
      mBox.translateOnAxis(
        new Three.Vector3(uVec.x, uVec.y + (max.y - min.y) / 2, uVec.z),
        1
      );
      mBox.scale.set(1.01, 1.01, 1.01);

      // other side rotate object
      let rotFillObj1 = rotFillObj.clone();
      let rotStrokeObj1 = rotStrokeObj.clone();
      rotFillObj1.rotateY(Math.PI);
      rotStrokeObj1.rotateY(Math.PI);
      rotFillObj.translateY(1.6);
      rotFillObj1.translateY(1.6);
      rotStrokeObj.translateY(1.6);
      rotStrokeObj1.translateY(1.6);

      // assets Objects group includes rotate objects...
      let asrtObj = new Three.Group();
      // asrtObj.add(rotFillObj);
      // asrtObj.add(rotFillObj1);
      // asrtObj.add(rotStrokeObj);
      // asrtObj.add(rotStrokeObj1);
      //asrtObj.add(upObj);
      asrtObj.add(vLine);
      asrtObj.add(vLine1);
      asrtObj.add(vLine2);
      asrtObj.add(vLine3);
      mBox.visible = false;
      asrtObj.add(mBox);
      asrtObj.scale.set(1, 1, 1);
      //asrtObj.translateY(newAltitude / scalevec.y);
      asrtObj.name = 'TransformGizmo';
      // add assets Objects Group
      object.add(asrtObj);

      // recover objects transform
      object.position.x = posVec.x;
      object.position.y = posVec.y;
      object.position.z = posVec.z;
      object.scale.set(scalevec.x, scalevec.y, scalevec.z);
      setTimeout(() => {
        SceneCreator.getDistances(layer);
      }, 100);
    }

    return object;
  };

  // keys in structure
  let keys = Object.keys(structure);

  // if exist in cached Objects
  if (element.type in cachedObject) {
    let objGroup = cachedObject[element.type].clone();
    return Promise.resolve(onLoadItem(objGroup.clone()));
  }

  // base Object/////
  let objGroup = null;

  let loadGLTFs = function(i) {
    if (i === keys.length) {
      // if end of keys
      cachedObject[element.type] = objGroup.clone(); //register to cachedObject
      return onLoadItem(cachedObject[element.type].clone());
    }
    if (keys[i] === 'base') {
      // if base Objects
      i++;
      return loadGLTFs(i);
    }
  };

  // load base to start //
  // return loadGLTF(dirName + structure["base"])
  return loadGLTF(structure['base'])
    .then(
      object => {
        // let textureURL = `${API_SERVER_URL}/uploads/assets/default/steel.jpg`;
        // let texture = loadTexture(textureURL);
        // texture.wrapS = Three.MirroredRepeatWrapping;
        // texture.wrapT = Three.MirroredRepeatWrapping;

        object.name = 'MainObject';
        let mat2 = new Three.MeshStandardMaterial({
          emissive: new Three.Color(0x666666).convertLinearToSRGB(),
          metalness: 0.7,
          roughness: 0.3
        });
        // mat2.envMap = textureCube;
        object.material = mat2;

        for (let j = 0; j < object.children.length; j++) {
          if (object.children[j].type === OBJTYPE_MESH) {
            object.children[j].material = mat2;
            object.children[j].receiveShadow = true;
          }
        }
        objGroup = object;
      },
      reason => {
        console.log('loadGLTF failed for reason:', reason);
        objGroup = GeomUtils.emptyBoxHolder(newWidth, newHeight, newDepth);
      }
    )
    .then(() => {
      return loadGLTFs(0);
    });
}

export function addEdgesToMesh(inChild) {
  let boxEdge = new Three.EdgesGeometry(inChild.geometry, 0.1);
  let boxEdgeMaterial = new Three.LineBasicMaterial({ color: 0x000000, transparent: true, opacity: 0.09 });
  let boxEdgeLine = new Three.LineSegments(boxEdge, boxEdgeMaterial);
  // inChild.add(boxEdgeLine);
}